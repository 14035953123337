@use 'sass:map';
@use '@angular/material' as mat;

@import "dark-theme";

@include mat.core();

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: white !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #383838;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

:root {
  --app-height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;

  @media not all and (hover: hover) {
    height: var(--app-height);
  }
}

.action-dangerous {
  color: red !important;
  font-weight: bold;
}

